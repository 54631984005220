.error-page {
  background: $backgroundWhiteColor;
  background-size: cover;
  padding: 40px 20px;

  &--404 {
    background: url('/assets/#{$mcs}/frontend-core/staticImages/error404.jpg') no-repeat right;
    background-size: cover;
  }

  &--500,
  &--502,
  &--503,
  &--504 {
    background: url('/assets/#{$mcs}/frontend-core/staticImages/error500.jpg') no-repeat right;
    background-size: cover;
  }

  &__container {
    @include container();
  }

  &__content {
    background: rgb(255 255 255 / .9);
    text-align: center;
    max-width: 100%;
    padding: 20px 20px 30px;

    @media (min-width: $screen-tablet-landscape) {
      max-width: 45%;
      margin-left: 5%;
    }
  }

  &__logo {
    margin: 20px 0;
    max-width: 180px;

    @media (min-width: $screen-tablet-landscape) {
      max-width: 200px;
    }
  }

  &__headline {
    @include fontSize($fontSizeXXLarge);
    display: block;
    width: 100%;
    margin: 20px 0;
    padding: 0;
    font-weight: $fontWeightBold;
    line-height: 130%;
  }

  &__search {
    position: relative;
    max-width: 500px;
    margin: 10px auto 20px;
  }

  &__text {
    @include fontSize($fontSizeMedium);
    color: $textBaseColor;
    margin: 10px 0 5px;
  }

  &__button {
    margin: 10px auto;
    max-width: 300px;
  }

  &__link {
    @include fontSize($fontSizeMedium);
    color: $textBaseColor;
    margin: 10px 0 5px;
    display: inline-block;
    font-weight: $fontWeightBold;
  }

  .search-autosuggest__input .input__wrapper {
    background: #e9e9e9;
  }
}
